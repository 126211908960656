import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/gatsby'

import { Box, ThemeProvider } from '@mui/material'
import { flockTheme, LibraryThemeProvider } from '@flock/shared-ui'
import ArticlesGrid from '../components/BlogComponents/ArticlesGrid'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import BlogHero from '../components/BlogComponents/BlogHero'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../components/utils'
import { CONTENTFUL_ENTRIES_URL, CONTENTFUL_ACCESS_TOKEN } from '../constants'
import ErrorCard from '../components/SalesforceOfferPageComponents/ErrorCard'

const pageTitle = 'Flock | Blog'
const pageDescription =
  'Confused about real estate investing? Read articles written by our real estate experts.'

export type PostData = {
  title: string
  imageUrl: string
  publishedAt: string
  blogText?: any
  description: string
  slug: string
  author?: {
    name?: string
  }
  embeddedAssetIdsToUrls?: Map<string, string>
}

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const formatContentfulResponse = (response: any) => {
  const items = response?.items
  const mediaAssets = response?.includes?.Asset

  const formattedResponse = []
  for (let i = 0; i < items.length; i += 1) {
    const mediaId = items[i]?.fields?.image?.sys?.id
    const selectedMedia = mediaAssets.filter(
      (media: any) => media?.sys?.id === mediaId
    )[0]

    const blogPost: PostData = {
      title: items[i]?.fields?.title,
      description: items[i]?.fields?.description,
      publishedAt: items[i]?.fields?.publishDate,
      slug: items[i]?.fields?.slug,
      imageUrl: `https://${selectedMedia?.fields?.file?.url?.replace(
        /^\/\//,
        ''
      )}`,
    }

    formattedResponse.push(blogPost)
  }

  return formattedResponse
}

const Blog = () => {
  const [blogData, setBlogData] = useState<PostData[]>([])
  const [pageError, setPageError] = useState(false)

  // Fetch data from API once when the component mounts
  useEffect(() => {
    // Define the async function to call the API
    const fetchData = async () => {
      try {
        const urlWithToken = `${CONTENTFUL_ENTRIES_URL}?access_token=${CONTENTFUL_ACCESS_TOKEN}&content_type=blogPost`
        const response = await fetch(urlWithToken)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const result = await response.json()
        if (!result?.items?.length) {
          throw new Error('Failed to fetch post data')
        }

        const formattedResult = formatContentfulResponse(result)
        setBlogData(formattedResult) // Set the data in state if the request is successful
      } catch (err) {
        // Handle blog load error
        setPageError(true)
      }
    }

    // Call the async function
    fetchData()
  }, []) // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (pageError) {
      Sentry.captureException(new Error(`Blog homepage failed to load`), {
        tags: {},
        extra: {},
      })
    }
  }, [pageError])

  useRecordPageDuration()
  useScrollDepthTracker()

  if (pageError) {
    return (
      <PageWrapper
        title="Flock | Blog"
        trackingName="blog"
        headerBackground="trustBlue.main"
      >
        <ThemeProvider theme={flockTheme}>
          <LibraryThemeProvider>
            <Box
              height="75vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              id="calendlyPopupRoot"
              sx={{ backgroundColor: 'trustBlue.main', overflow: 'hidden' }}
            >
              <ErrorCard text="Please try refreshing the page or reach out to us below." />
            </Box>
          </LibraryThemeProvider>
        </ThemeProvider>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper
      title="Flock | Blog"
      trackingName="blog"
      headerBackground="trustBlue.main"
    >
      <BlogHero />
      <ArticlesGrid articles={blogData} />
    </PageWrapper>
  )
}

export default Blog
